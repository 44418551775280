<template>
  <div>
    <footerNavigation :entrykey="entrykey" @saveClick="submitForm()"></footerNavigation>

    <div v-if="showWelcome">
      <div class="container">
        <div class="row">
          <div class="col-8 col-md-6 mx-auto mt-5 bg-light rounded p-4">
              <p class="lead">Welkom bij DagjeWeg.NL Tickets! Via dit fomulier kun je alle gegevens invullen die we nodig hebben om de verkooppagina te vullen en de verkoop te starten.</p>
              <p class="lead">Lukt het niet om alle gegevens in één keer aan te leveren, sla je formulier dan tussendoor op en maakt en een andere keer af.</p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!showWelcome">
      <div v-farewell="onLeave"></div>
      <companyInfo v-if="currentPage == 'company'" />
      <imageUploader v-if="currentPage == 'images'" />
      <ticketKinds v-if="currentPage == 'tickets'" />
      <conditionsPage v-if="currentPage == 'conditions'" />
      <extraInfo v-if="currentPage == 'extra'" />
      <overviewPage @completeForm="completeForm()" v-if="currentPage == 'complete'" />
      <b-modal size="xl" no-close-on-esc no-close-on-backdrop hide-footer hide-header centered v-model="openPermissionModal">
        <photoPermission @deleteFile="deleteFile" @savePhotoPermission="savePhotoPermission" :entryKey="entrykey" :file="photosWithoutPermissions[0]"></photoPermission>
      </b-modal>
      <b-modal hide-footer centered hide-header v-model="showLeaveModal"><b>Let op:</b> je hebt nog niet-opgeslagen informatie op deze pagina. Wil je niet eerst je informatie opslaan?</b-modal>
    </div>
  </div>
</template>

<script>

import footerNavigation from './footerNavigation.vue'
import companyInfo from './companyInfo.vue'
import ticketKinds from './ticketKinds.vue'
import imageUploader from '../shared/imageUploader.vue'
import conditionsPage from './conditionsPage.vue'
import extraInfo from './extraInfo.vue'
import overviewPage from './overviewPage.vue'
import photoPermission from '../shared/photoPermission.vue'
import draggable from 'vuedraggable'

import axios from 'axios'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


export default {
  components: {
    footerNavigation, companyInfo, imageUploader, ticketKinds, conditionsPage, extraInfo, overviewPage, photoPermission
  },
  props: ["payload", "entrykey", "files", "photos_without_permission", "page"],
  data () {
    return {
      showLeaveModal: false
      // currentPage: 'company'
    }
  },
  computed: {
    showWelcome() {
      return false
    },
    openPermissionModal() {
      return this.photosWithoutPermissions.length > 0
    },
    photosWithoutPermissions(){
      return this.$store.getters.photosWithoutPermissions
    },
    hasUnsavedContent() {
      return ( JSON.stringify(this.currentPayload) !==  JSON.stringify(this.savedPayload))
    },
    currentPage(){
      return this.$store.getters.selectedPage
    },
    currentPayload(){
      return this.$store.getters.payload
    },
    savedPayload(){
      return this.$store.getters.savedPayload
    }
  },
  methods: {
    deleteFile(file){
      axios.get(`/entries/${this.entrykey}/delete-file/${file.id}.json`)
      .then(response => {
        // console.log(response)
        this.$store.dispatch('setFiles',response.data.files)
        this.$store.dispatch('setPhotosWithoutPermission', response.data.photos_without_permission)
      })
    },
    savePhotoPermission(file){
      console.log("saving photo permission")
      console.log(file)
      return axios.put(`/entries/${this.entrykey}/update-file/${file.id}`, {file: file})
      .then(response => {
        console.log(response)
        this.$store.dispatch('setFiles', response.data.files)
        this.$store.dispatch('setPhotosWithoutPermission', response.data.photos_without_permission)
        console.log("DONE")
      })
    },
    onLeave(){
      console.log("user is leaving...")
      if (this.hasUnsavedContent){
        // alert("weet je het zeker?")
        this.showLeaveModal = true
      }
    },
    
  // selectPage(page){
    //   console.log(`clicking: ${page}`)
    //   this.currentPage = page
    //   // return
    // },
    completeForm(){
      return axios.put(`/entries/${this.entrykey}?complete=true`, {payload: this.currentPayload})
        .then(response => {
          this.$store.dispatch('setButtonState', {'button': 'completeForm', 'disabled': 'true', 'class': 'btn-success', 'message': 'Bedankt! De ingevoerde informatie is verzonden.'})
          setTimeout(() => {  window.location = `/entries/${this.entrykey}` }, 1000);
        })
    },
    submitForm(){
      return axios.put(`/entries/${this.entrykey}`, {payload: this.currentPayload})
      .then(response => {
        this.$store.dispatch('setButtonStateReset', {'button': 'footerSave', 'disabled': false, 'class': 'btn-success', 'message': 'Opgeslagen!'})
        this.$store.dispatch('setSavedPayload', this.currentPayload)
        // return response.data
      })
    },
    setInitialSegments(){
      var segments = [{company: {}}, {tickets: []}, {conditions: []}, {extra: {}}]
      for (var segment in segments){
        this.$store.dispatch('setInitialPayloadSegement', segments[segment])
      }
    }
  },
  created(){
    this.$store.dispatch('setInitialPayload', this.payload)
    this.$store.dispatch('setSavedPayload', this.payload)
    if (this.page == null) {
      this.$store.dispatch('setSelectedPage', 'company')  
    } else {
      this.$store.dispatch('setSelectedPage', this.page)  
    }
    
    this.$store.dispatch('setKey', this.entrykey)
    this.$store.dispatch('setFiles', this.files)
    this.$store.dispatch('setPhotosWithoutPermission', this.photos_without_permission)
    this.setInitialSegments()
  }
}
</script>

<style lang="css" scoped>
/*@import 'bootstrap/dist/css/bootstrap.css'
@import 'bootstrap-vue/dist/bootstrap-vue.css'*/

</style>