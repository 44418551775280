<template>
	<div class="form-group">
	  <label v-html="label"></label>

	  <input v-if="!mask" ref="textfield" v-on:keyup.enter="emitEnter" @input="handleInput" :type="type" class="form-control" :placeholder="placeholder" v-model="content">
	  <masked-input v-if="mask"  v-on:keyup.enter="emitEnter" @input="handleInput" class="form-control" :placeholder="placeholder" v-model="content" :mask="mask" />
  </div>            
</template>

<script>

import { mapFields } from 'vuex-map-fields'
import MaskedInput from 'vue-masked-input'

export default {

	props: ["label", "value", "type", "placeholder", "mask"],
	components:{
		MaskedInput
	},
	computed: {

	},
	methods: {
		focus(){
			this.$refs.textfield.focus()
		},
		emitEnter(){
			this.$emit('keyupEnter')
		},
		saveValue(v){
			this.content = v
	    // this.$emit('input', this.content)
		},
		handleInput(e){
			this.$emit('input', this.content)
		}
	},
  data () {
    return {
    	content: this.value
    }
  },
	watch: {
	  value: {
	  	handler: function(val, oldVal) {
		  	this.saveValue(val)
		  }
	 	}
	}
}
</script>

<style lang="css" scoped>
</style>