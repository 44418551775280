/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import Vue from 'vue/dist/vue.esm'

import Vuex from 'vuex'


import lodash from 'lodash'
import axios from 'axios' // for making API calls

import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)


import VueClip from 'vue-clip'
Vue.use(VueClip)

// load Vuex store
import store from '../store/index' 
// make store accessible in all components via window.store
// window.store = store

import VueCurrencyInput from 'vue-currency-input'
const pluginOptions = { currency: "EUR", locale: "nl" }
Vue.use(VueCurrencyInput, pluginOptions)

import { farewellDirectivePlugin } from 'vue-farewell'
Vue.use(farewellDirectivePlugin, {aggressive: true})

import VueCurrencyFilter from 'vue-currency-filter'
Vue.use(VueCurrencyFilter, {symbol: '€', fractionSeperator: ',', fractionCount: 2})

import formButton from '../components/shared/formButton.vue'
Vue.component('formButton', formButton)

import dagjewegTicketsForm from '../components/dagjewegtickets/dagjewegTicketsForm.vue'
Vue.component('dagjewegticketsform', dagjewegTicketsForm)

import werkForm from '../components/werk/werkForm.vue'
Vue.component('werkform', werkForm)

import entriesIndex from '../components/admin/entriesIndex.vue'
Vue.component('entriesindex', entriesIndex) 

import adminNavbar from '../components/admin/adminNavbar.vue'
Vue.component('adminnavbar', adminNavbar) 

import imageForm from '../components/afbeeldingen/imageForm.vue'
Vue.component('imageform', imageForm) 


axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
// window.store = store
document.addEventListener("turbolinks:load", function(){
  if (document.getElementById("main-wrapper").dataset.useVue == 'true') {
    const app = new Vue({
      el: '[data-behaviour="vue"]',
      store
      // mixin: [TurbolinksAdapter],
    })
  }
});