<template>
  <div>
    <div class="container pt-5">
      <div class="row">
        <div class="col">
          <h1>Extra informatie</h1>
          <p class="lead">
            Wat we onze bezoekers ook graag vertellen
          </p>
        </div>
      </div>
      <div class="row d-flex justify-content-between">
        <div class="col col-md-6 col-lg-6">

           <fieldset>
            <legend>E-tickets</legend>
            <p>Mogen de klanten hun ticket op hun mobiel laten zien?</p>
            <b-form-group label="">
              <b-form-radio v-model="payload.segments.extra.must_print" name="print" value="No">Ja, geen probleem</b-form-radio>
              <b-form-radio v-model="payload.segments.extra.must_print" name="print" value="Yes">Nee, de tickets moeten worden geprint</b-form-radio>
            </b-form-group>
          </fieldset>

          <fieldset>
            <legend>Tickets annuleren</legend>
            <p>Het komt wel eens voor dat bezoekers contact opnemen met DagjeWeg.NL Tickets, om te vragen of we hun tickets willen annuleren en het geld willen terugstorten. Hoe wil je dat DagjeWeg.NL Tickets hiermee omgaat?<br/>
            <span class="font-weight-bold">LET OP:</span> Het annuleren van tickets brengt kosten met zich mee. De commissie van DagjeWeg.Tickets zal blijven staan, en bij terugbetalen worden terugbetaalkosten gerekend.</p>
            <b-form-group label="">
              <b-form-radio v-model="payload.segments.extra.cancel_policy" name="cancel" value="Nooit">Annuleren en terugbetalen mag nooit</b-form-radio>
              <b-form-radio v-model="payload.segments.extra.cancel_policy" name="cancel" value="In overleg">Overleg in zulke gevallen altijd met ons</b-form-radio>
              <b-form-radio v-model="payload.segments.extra.cancel_policy" name="cancel" value="Eigen inzicht">Liever niet, maar de klantenservice van DagjeWeg.NL Tickets mag die afweging zelf maken per geval</b-form-radio>
            </b-form-group>
          </fieldset>

         <fieldset>
            <legend>Barcodes</legend>
            <p>Op elk ticket komt een barcode. Welke bron gebruiken we voor de barcodes?</p>
            <b-form-group label="">
              <b-form-radio v-model="payload.segments.extra.barcdode_source" name="barcdode_source" value="random">DagjeWeg.NL Tickets mag zelf willekeurig barcodes genereren</b-form-radio>
              <b-form-radio v-model="payload.segments.extra.barcdode_source" name="barcdode_source" value="own_batch">Wij leveren een reeks barcodes aan uit ons systeem</b-form-radio>
              <b-form-radio v-model="payload.segments.extra.barcdode_source" name="barcdode_source" value="dwt_batch">Wij willen graag een door DagjeWeg.NL Tickets gegenereerde lijst ontvangen</b-form-radio>
              <b-form-radio v-model="payload.segments.extra.barcdode_source" name="barcdode_source" value="enviso">Een koppeling met Enviso (Ganter Tickets)</b-form-radio>
              <b-form-radio v-model="payload.segments.extra.barcdode_source" name="barcdode_source" value="globalticket">Een koppeling met Globalticket</b-form-radio>
              <b-form-radio v-model="payload.segments.extra.barcdode_source" name="barcdode_source" value="prioticket">Een koppeling met PrioTicket</b-form-radio>
              <b-form-radio v-model="payload.segments.extra.barcdode_source" name="barcdode_source" value="recras">Een koppeling met Recras</b-form-radio>
              <b-form-radio v-model="payload.segments.extra.barcdode_source" name="barcdode_source" value="leisure_king">Een koppeling met LeisureKing</b-form-radio>
              <b-form-radio v-model="payload.segments.extra.barcdode_source" name="barcdode_source" value="wtc">Een koppeling met TicketCounter/WhereToCard</b-form-radio>
              <b-form-radio v-model="payload.segments.extra.barcdode_source" name="barcdode_source" value="stc">Een koppeling met Shake The Counter</b-form-radio>
            </b-form-group>
          </fieldset>


         <fieldset>
            <legend>Barcodesoort</legend>
            <p>Er bestaan verschillende soorten barcodes. Welk type barcode kun je scannen?</p>
            <b-form-group label="">
              <b-form-radio v-model="payload.segments.extra.barcdode_type" name="barcdode_type" value="code_128"><i class="fas fa-barcode"></i> Code 128 (normale EAN streepjescode)</b-form-radio>
              <b-form-radio v-model="payload.segments.extra.barcdode_type" name="barcdode_type" value="qr"><i class="fas fa-qrcode"></i> QR Code</b-form-radio>
            </b-form-group>
          </fieldset>

          <fieldset>
            <legend>Geldigheid tickets</legend>
            <p>Tot wanneer zijn deze tickets geldig?</p>
            <b-form-group label="">
              <b-form-radio v-model="payload.segments.extra.ticket_validity_months" name="validity" value="3">3 maanden na aanschaf</b-form-radio>
              <b-form-radio v-model="payload.segments.extra.ticket_validity_months" name="validity" value="6">6 maanden na aanschaf</b-form-radio>
              <b-form-radio v-model="payload.segments.extra.ticket_validity_months" name="validity" value="9">9 maanden na aanschaf</b-form-radio>
              <b-form-radio v-model="payload.segments.extra.ticket_validity_months" name="validity" value="12">12 maanden na aanschaf</b-form-radio>
              <b-form-radio v-model="payload.segments.extra.ticket_validity_months" name="validity" value="other">Op een specifieke datum</b-form-radio>
            </b-form-group>
            <inputField v-if="payload.segments.extra.ticket_validity_months == 'other' " label="Datum" placeholder="31-12-2020" v-model="payload.segments.company.ticket_validity_date" mask="11-11-1111" type="text"/>
          </fieldset>


          <fieldset>
            <legend>Toegankelijkheid</legend>

            
            <p>Is de locatie buggy- en rolstoeltoegankelijk?</p>
            <b-form-group label="">
              <b-form-radio v-model="payload.segments.extra.wheelchair_accessible" name="wheelchair" value="Yes">Ja</b-form-radio>
              <b-form-radio v-model="payload.segments.extra.wheelchair_accessible" name="wheelchair" value="No">Nee</b-form-radio>
              <b-form-radio v-model="payload.segments.extra.wheelchair_accessible" name="wheelchair" value="Partial">Gedeeltelijk</b-form-radio>
            </b-form-group>


            <p>Is de locatie geschikt voor mensen met een visuele beperking?</p>
            <b-form-group label="">
              <b-form-radio v-model="payload.segments.extra.visual_impaired_accessible" name="visual_impaired" value="Yes">Ja</b-form-radio>
              <b-form-radio v-model="payload.segments.extra.visual_impaired_accessible" name="visual_impaired" value="No">Nee</b-form-radio>
              <b-form-radio v-model="payload.segments.extra.visual_impaired_accessible" name="visual_impaired" value="Partial">Gedeeltelijk</b-form-radio>
            </b-form-group>


            <inputField label="Opmerking over toegankelijkheid" placeholder="Vul hier eventueel een toelichting in over de toegankelijkheid" v-model="payload.segments.extra.accessible_comment" type="text"/>

          </fieldset>


          <fieldset>
            <legend>Parkeren</legend>
            <p>Op eigen terrein</p>
            <b-form-group label="">
              <b-form-radio v-model="payload.segments.extra.private_parking" name="private_parking" value="unknown">Onbekend</b-form-radio>
              <b-form-radio v-model="payload.segments.extra.private_parking" name="private_parking" value="free">Is gratis</b-form-radio>
              <b-form-radio v-model="payload.segments.extra.private_parking" name="private_parking" value="paid">
                Is betaald
                <currency-input v-if="payload.segments.extra.private_parking == 'paid'" ref="priceField" v-model="payload.segments.extra.private_parking_costs" class="form-control" placeholder="€7,50" />
              </b-form-radio>
              <b-form-radio v-model="payload.segments.extra.private_parking" name="private_parking" value="not possible">Is niet mogelijk</b-form-radio>
            </b-form-group>

            <p>In de buurt</p>
            <b-form-group label="">
              <b-form-radio v-model="payload.segments.extra.nearby_parking" name="nearby_parking" value="unknown">Onbekend</b-form-radio>
              <b-form-radio v-model="payload.segments.extra.nearby_parking" name="nearby_parking" value="free">Is gratis</b-form-radio>
              <b-form-radio v-model="payload.segments.extra.nearby_parking" name="nearby_parking" value="paid">
                Is betaald
                <currency-input v-if="payload.segments.extra.nearby_parking == 'paid'" ref="priceField" v-model="payload.segments.extra.nearby_parking_costs" class="form-control" placeholder="€7,50" />
              </b-form-radio>
              <b-form-radio v-model="payload.segments.extra.nearby_parking" name="nearby_parking" value="not possible">Is niet mogelijk</b-form-radio>
            </b-form-group>

          </fieldset>

          <fieldset>
            <legend>Huisdieren</legend>
            <p>Is het toegestaan een aangelijnd huisdier mee te nemen naar de locatie?</p>
            <b-form-group label="">
              <b-form-radio v-model="payload.segments.extra.pets" name="pets" value="Allowed">Ja</b-form-radio>
              <b-form-radio v-model="payload.segments.extra.pets" name="pets" value="Not allowed">Nee</b-form-radio>
            </b-form-group>
          </fieldset>

          <fieldset>
            <legend>Adverteren</legend>
            <p>Mag DagjeWeg.NL Tickets het aanbod toevoegen aan de productfeed? Met deze productfeed wordt extra geadverteerd om de verkoop te stimuleren. Dit brengt geen extra kosten met zich mee.</p>
            <b-form-group label="">
              <b-form-radio v-model="payload.segments.extra.can_advertise" name="advertise" value="Yes">Ja</b-form-radio>
              <b-form-radio v-model="payload.segments.extra.can_advertise" name="advertise" value="No">Nee</b-form-radio>
            </b-form-group>

          </fieldset>


        </div>

        <div class="col col-md-6 col-lg-4">
          <infoblock icon="fas fa-info">
            <p>Hoe meer onzekerheden we weg kunnen nemen, hoe meer tickets er geboekt zullen worden.</p>
            <p><b>Tip:</b><br>Denk mee met de bezoeker. De tickets een paar maanden langer geldig laten zijn, is gemakkelijker dan de toegankelijkheid verbeteren.</p>
          </infoblock>

          <infoblock icon="fas fa-question">
            <p>Vragen bij het invullen?</p>
            <p><i class="fas fa-question"></i> 0318 640 289</p>
            <p><i class="fas fa-at"></i> service@dagjewegtickets.nl</p>
          </infoblock>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import inputField from '../shared/inputField.vue'
import infoBlock from '../shared/infoBlock.vue'
import { mapFields } from 'vuex-map-fields'

export default {

  components: {infoblock: infoBlock, inputField},
  data () {
    return {

    }
  },
  computed: {
    ...mapFields([
      // 'payload.segments.company.kvk'
      // ...
    ]),
    payload(){
      return this.$store.getters.payload
    },
  }
}
</script>

<style lang="css" scoped>
</style>