<template>
  <div>
  <b-navbar toggleable="lg" type="dark" variant="primary" fixed="top">
    <b-navbar-brand href="#">Eskes Media Formulieren</b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item href="/entries/">Openstaande formulieren</b-nav-item>
        <b-nav-item v-if="project != 3" href="/entries/?completed=true">Actieve formulieren</b-nav-item>
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">

        <b-nav-item-dropdown text="Project" right>
          <b-dropdown-item href="/">DagjeWeg.NL Tickets</b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown right>
          <!-- Using 'button-content' slot -->
          <template slot="button-content">Ingelogd</template>
          
          <li>
            <a rel="nofollow" class="dropdown-item" data-method="delete" href="/users/sign_out">Uitloggen</a>
          </li>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
  </div>
</template>

<script>
export default {

  props: ['project'],

  data () {
    return {

    }
  }
}
</script>

<style lang="css" scoped>
</style>