<template>
  <div>
    <b-navbar toggleable="sm" type="dark" variant="primary" >
      <b-navbar-brand href="#">DagjeWeg.NL Tickets</b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item  v-b-modal.modal-center href="javacript:void(0)">Contact</b-nav-item>
        </b-navbar-nav>

      </b-collapse>
    </b-navbar>

      <b-modal id="modal-center" centered title="Hulp nodig?" hide-footer>
        <p class="my-4 lead">Heb je hulp nodig bij het invullen van dit formulier, of heb je vragen? Neem gerust contact op: </p>
        <p class="lead">0318 640 289 of service@dagjewegtickets.nl</p>
      </b-modal>

    <!-- footer -->
    <b-navbar toggleable="sm" type="light" variant="light" fixed="bottom">
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="mx-auto w-75">
          <b-navbar-nav class="w-100 d-flex justify-content-between">
            <b-nav-item :active="isActive('company')" @click="selectPage('company')">1. Gegevens</b-nav-item>
            <b-nav-item :active="isActive('images')" @click="selectPage('images')">2. Afbeeldingen</b-nav-item>
            <b-nav-item :active="isActive('tickets')" @click="selectPage('tickets')">3. Aanbod</b-nav-item>
            <b-nav-item :active="isActive('conditions')" @click="selectPage('conditions')">4. Voorwaarden</b-nav-item>
            <b-nav-item :active="isActive('extra')" @click="selectPage('extra')">5. Extra informatie</b-nav-item>
            <b-nav-item :active="isActive('complete')" @click="selectPage('complete')">6. Voltooien</b-nav-item>
          </b-navbar-nav>          
        </b-navbar-nav>
          <formButton @click="$emit('saveClick')" class="btn btn-primary" name="footerSave" title="Opslaan"></formButton>

      </b-collapse>
    </b-navbar>
  </div>

</template>

<script>
import axios from 'axios'
export default {


  props: ["entrykey"],
  computed: {
    selectedPage(){
      return this.$store.getters.selectedPage
    }
  },
  methods: {
    isActive(page){
      return (this.selectedPage == page)
    },
    selectPage(page){
      axios.get(`/entries/${this.entrykey}/set-current-page/${page}`)
      this.$store.dispatch("setSelectedPage", page)
      this.$emit('selectPage', page)
    }
  },
  data () {
    return {

    }
  }
}
</script>

<style lang="css" scoped>
.nav-link{
  cursor: pointer !important;
  font-weight: 600;
}

.active{
  font-weight: bolder;
}
</style>
