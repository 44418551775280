<template>
  <div>
    <p class="mx-5">Selecteer hier wat voor type kaartjes je wilt verkopen op DagjeWeg.Tickets. Moeten ze na het bestellen nog reserveren op jouw website, kies dan Voucher. Wij kunnen ook met ticketsystemen verbinden, zie bij stap 5. Extra informatie het overzicht van alle ticketsytemen. Gebruik je een van die ticketsystemen? Kies dan hier voor Ticket.</p>
    <center>
    <b-form-group label="Type Ticket">
      <b-form-radio-group 
        v-model="ticket.type" 
        :options="ticketTypeOptions" 
        name="ticket_types"
        buttons
        button-variant="outline-primary"
        size="sm"
      >
      </b-form-radio-group>
    </b-form-group>
    
    <b-form-group label="Soort tickets" v-if="ticket.type">
      <b-form-radio-group 
        v-model="ticket.category" 
        :options="ticketCategoryOptions" 
        name="ticket_categories"
        buttons
        button-variant="outline-primary"
        @change="setValueToName"
        size="sm"
      >
      </b-form-radio-group>
    </b-form-group>
    </center>
  
    <div v-if="ticket.type && ticket.category">      

      <div class="form-group">
        <label>Naam ticketsoort</label>
        <input maxlength="42" maref="nameField" type="text" class="form-control" v-bind:class="{'is-invalid' : nameIsNotUnique }" placeholder="Naam ticketsoort" v-model="ticket.name">
        <span v-if="!nameIsNotUnique" class="text-muted small">Maximaal 42 karakters. Nog {{ (42 - name)}} karakters over.</span>
        <span v-if="nameIsNotUnique" class="small invalid-feedback">Naam moet voor de administratie uniek zijn. Er is al een ticketsoort met de naam {{ticket.name}}.</span>
      </div>
      <div v-if="!nameIsNotUnique">
        <div class="row">
          <div class="col-3">
            <div class="form-group">
              <label>Vanaf leeftijd</label>
              <input v-model="ticket.min_age" class="form-control" type="number" min="0" max="120"/>
              <small class="form-text text-muted">Leeglaten indien niet van toepassing.</small>
            </div>    
          </div> 
          <div class="col-3">
            <div class="form-group">
              <label>Tot en met leeftijd</label>
              <input v-model="ticket.max_age" class="form-control" type="number" min="0" max="120" />
              <small class="form-text text-muted">Leeglaten indien niet van toepassing.</small>
            </div>    
          </div>
        </div>

        <div class="form-group">
          <label>Beschrijving</label>
          <textarea maxlength="125" rows="2" class="form-control" placeholder="Beschrijving van het ticketsoort" v-model="ticket.description"></textarea>
          <span class="text-muted small">Maximaal 125 karakters. Nog {{ (125- description)}} karakters over.</span>
        </div>
        <div class="form-group">
          <label>Gratis product <span class="font-weight-light">(optioneel)</span></label>
          <input maxlength="50" class="form-control" placeholder="Bijv. Gratis kopje koffie" v-model="ticket.product"></input>
          <span class="text-muted small">Maximaal 50 karakters. Nog {{ (50 - product)}} karakters over.</span>
        </div>
        <div class="form-group" v-if="ticket.type == 'voucher'">
          <label>Inleverinstructie voor de voucher (verplicht)</label>
          <textarea rows="2" class="form-control" placeholder="Wat moet de consument doen om een definitieve resevering te maken na aankoop?" v-model="ticket.instructions"></textarea>
          <span class="text-muted small"><b>Voorbeeld 1:</b> 'Ga na de betaling naar www.example.com/reserveren om met behulp van de vouchercode een reservering te maken.'</span><br>
          <span class="text-muted small"><b>Voorbeeld 2:</b> 'Bel tijdens kantooruren naar 0318-200214 om een reservering te maken. Houdt de vouchercode's bij de hand.'</span>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label>Reguliere prijs</label>
              <currency-input v-model="ticket.regular_price" class="form-control" placeholder="€10,00" />
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label>Aanbiedingsprijs</label>
              <currency-input ref="priceField" v-model="ticket.price" class="form-control" placeholder="€7,50" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
export default {

  props: ["ticket", "tickets"],
  computed: {
    ticket_names() {
      let arr = []
      for (var i = this.tickets.length - 1; i >= 0; i--) {
        arr.push(this.tickets[i].name)          
      }
      return arr
    },
    description() {
      if(this.ticket.description) {
        return this.ticket.description.length;
      } else {
        return 0;
      }
    },
    product() {
      if(this.ticket.product) {
        return this.ticket.product.length;
      } else {
        return 0;
      }
    },
    nameIsNotUnique(){
      return this.nameOccurences > 1
    },
    nameOccurences(){
      let count = this.ticket_names.reduce((n, x) => n + (x === this.ticket.name), 0);
      return count
    },
    name() {
      if(this.ticket.name) {
        return this.ticket.name.length;
      } else {
        return 0;
      }
    }
  },
  methods: {
    save(){
    },
    setValueToName(){
      // console.log(this.ticket.category)
      setTimeout(function () {
        
        this.ticket.name = this.ticket.category
        this.$refs.nameField.focus()
      }.bind(this), 50)
    }
  },
  data () {
    return {
      // ticket_type: undefined,
      // regular_price: undefined,
      // ticket_name: '',
      // price: '',
      // ticket_description: '',
      // category: undefined,
      ticketCategoryOptions: ['Gezin','Senior','Volwassene','Kind','Peuter/kleuter','Overig'],
      ticketTypeOptions: [
        {text: 'Ticket (inleveren/tonen bij de kassa)', value: 'ticket'}, 
        {text: 'Voucher (klant moet achteraf nog reserveren)', value: 'voucher'}
      ]
    }
  }
}
</script>

<style lang="css" scoped>
</style>