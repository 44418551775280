<template>
  <div>
    <div class="container pt-5">
      <b-modal @ok="saveNewTicketKind" cancel-title="Annuleer" size="lg" :ok-disabled="ticketCanBeSaved(ticketToEdit)" id="ticket-modal" ref="ticket-modal" title="Ticketsoort bewerken">
        <ticketForm ref="ticketForm" :ticket="ticketToEdit" :tickets="tickets"/>
      </b-modal>

      <div class="row">
        <div class="col">
          <h1>Aanbieding</h1>
          <p class="lead">
            De soorten tickets die we gaan verkopen
          </p>
        </div>
      </div>
      <div class="row d-flex justify-content-between">
        <div class="col col-md-6 col-lg-8">

          <button @click="addTicket" class="btn btn-primary"><i class="fas fa-plus"></i> Voeg een ticketsoort toe</button>


          <div v-if="tickets.length" class="bg-light rounded p-3 mt-4">
            <h3>Toegevoegde ticketsoorten</h3>
            <table class="table">
              <tr v-for="(ticket, i) in tickets" :key="`ticket-${i}`">
                <td v-if="ticket.category">
                  <strong>{{ticket.name}}</strong><br>
                  {{ticket.description}}
                  <span class="small text-muted" v-if="ticket.min_age || ticket.max_age">
                    <span v-if="ticket.min_age && !ticket.max_age">vanaf {{ticket.min_age}} jaar</span>
                    <span v-if="ticket.min_age && ticket.max_age">{{ticket.min_age}} t/m {{ticket.max_age}} jaar</span>
                    <span v-if="!ticket.min_age && ticket.max_age">tot en met {{ticket.max_age}} jaar</span>
                  </span>
                </td>
                <td colspan="3" v-if="!ticket.category" class="text-center text-muted">
                  Naamloos ticket
                </td>
                <td v-if="ticket.category" class="small strike pt-3"><span v-if="ticket.regular_price != ticket.price">{{ticket.regular_price | currency }}</span></td>
                <td v-if="ticket.category">{{ticket.price | currency }}</td>
                <td>
                  <button @click="showEditForm(ticket)" class="btn btn-sm btn-primary">Bewerken</button>
                </td>
                <td>
                  <button @click="removeTicketFromForm(i)" class="btn btn-sm btn-danger">Verwijderen</button>
                </td>
              </tr>
            </table>
          </div>

        </div>

        <div class="col col-md-6 col-lg-4">
          <infoblock icon="fas fa-info">
            <p>De ticketprijs mag op DagjeWeg.NL Tickets niet hoger zijn dan ergens anders. Als je ergens anders een <b>tijdelijke</b> aanbieding hebt met een lagere ticketprijs, laat het ons weten!</p>
          </infoblock>

          <infoblock icon="fas fa-question">
            <p>Vragen bij het invullen?</p>
            <p><i class="fas fa-question"></i> 0318 640 289</p>
            <p><i class="fas fa-at"></i> service@dagjewegtickets.nl</p>
          </infoblock>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import infoBlock from '../shared/infoBlock.vue'
import ticketForm from './ticketForm.vue'
export default {
  components: {infoblock: infoBlock, ticketForm},
  data () {
    return {
      newTicketKind: {},
      ticketToEdit: {}
    }
  },
  methods: {
    removeTicketFromForm(num){
      let payload = {...this.payload}
      payload.segments.tickets.splice(num, 1)
      this.$store.dispatch('setInitialPayload',payload)
    },
    showEditForm(ticket){
      this.ticketToEdit = ticket
      // this.$bvModal.show('ticket-modal')
      this.$refs['ticket-modal'].show()
    },
    addTicket(){
      let payload = {...this.payload}
      if (payload.segments.tickets == undefined){
        payload.segments.tickets = []
      }
      payload.segments.tickets.push({name: ''})
      this.$store.dispatch('setInitialPayload',payload)
      this.showEditForm(payload.segments.tickets[payload.segments.tickets.length - 1])
    },
    saveNewTicketKind(){
      // this.$refs.newTicketForm.save()
    },
    saveTicketKind(tk){
      // console.log("ok button pressed")
      // console.log(tk)
    },
    ticketCanBeSaved(t){
      if (t.type == undefined){
        return true
      } else if (t.type == "voucher" && t.instructions && t.instructions.length == 0){
        return true
      } else if (t.type == "voucher" && !t.instructions){
        return true
      } else {
        console.log(t.type)
        console.log(t.instructions)
        return false
      }
      
    }
  },
  computed: {
    tickets(){
      if (this.payload.segments.tickets && this.payload.segments.tickets && this.payload.segments.tickets.length > 0){
        return this.payload.segments.tickets
      } else {
        return []
      }
    },
    payload(){
      return this.$store.getters.payload
    },  
  }
  
}
</script>

<style lang="css" scoped>
  
  .strike{
    text-decoration: line-through;
  }
</style>