<template>
  <div>
    <div class="container pt-5">
      <div class="row">
        <div class="col">
          <h1>Voorwaarden voor klanten</h1>
          <p class="lead">
            Welke beperkingen gelden er?
          </p>
        </div>
      </div>
      <div class="row d-flex justify-content-between">
        <div class="col col-md-6 col-lg-8">

          <button v-if="conditions.length == 0" @click="addCondition" class="btn btn-primary"><i class="fas fa-plus"></i> Voeg extra voorwaarde toe</button>

          <div class="list-group my-2" v-if="conditions.length">
            
            <draggable handle=".handle" v-model="conditions" @start="drag=true" @end="drag=false">
              <div class="list-group-item" :key="`condition-${i}`"  v-for="(condition, i) in conditions">
                <div class="input-group">
                  <i class="fas fa-sort fa-lg handle d-flex align-self-center mr-2"></i>
                  <textarea maxlength="250" class="form-control" type="text" v-model="condition.content"></textarea>
                  <div class="input-group-append">
                    <button @click="removeCondition(i)" class="btn btn-outline-danger"><i class="fas fa-trash"></i></button>
                  </div>
                </div>
                  <span class="text-muted small">Maximaal 250 karakters. Nog {{ (250- condition.content.length)}} karakters over.</span>
              </div>
            </draggable>

            <div class="list-group-item">
              <button @click="addCondition" class="btn btn-primary"><i class="fas fa-plus"></i> Voeg nog extra voorwaarde toe</button>
            </div>
          </div>

          <h5 class="mt-4">Altijd geldende voorwaarden van DagjeWeg.NL Tickets</h5>
          <ul class="list-group">
            <li class="list-group-item">Gekochte tickets of vouchers worden niet geruild of retour genomen.</li>
            <li class="list-group-item">Bekijk vooraf altijd de actuele openingstijden van de aanbieder.</li>
            <li class="list-group-item">Op het bestellen van tickets zijn de algemene voorwaarden van DagjeWeg.NL Tickets van toepassing.</li>
          </ul>

        </div>

        <div class="col col-md-6 col-lg-4">
          <infoblock icon="fas fa-info">
            <p>Hoe meer voorwaarden je toevoegt, hoe groter de kans is dat de klant afhaakt. Voeg alleen voorwaarden toe die echt noodzakelijk zijn.</p>
          </infoblock>

          <infoblock icon="fas fa-question">
            <p>Vragen bij het invullen?</p>
            <p><i class="fas fa-question"></i> 0318 640 289</p>
            <p><i class="fas fa-at"></i> service@dagjewegtickets.nl</p>
          </infoblock>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import infoBlock from '../shared/infoBlock.vue'
import conditionField from './conditionField.vue'
import draggable from 'vuedraggable'

export default {
  components: {draggable, infoblock: infoBlock, conditionField},
  data () {
    return {

    }
  },
  computed:{
    conditions: {
      get() {
          return this.payload.segments.conditions
      },
      set(value) {
          this.$store.dispatch('updateList', value)
      }
    },
    conditionsList() {
      return this.payload.segments.conditions
    },
    payload(){
      return this.$store.getters.payload
    },
  },
  methods: {
    removeCondition(num){
      let payload = {...this.payload}
      payload.segments.conditions.splice(num, 1)
      this.$store.dispatch('setInitialPayload',payload)
    },
    addCondition(){
      let payload = {...this.payload}
      if (payload.segments.conditions == undefined){
        payload.segments.conditions = []
      }
      payload.segments.conditions.push({content: ''})
      this.$store.dispatch('setInitialPayload',payload)
    }
  }
}
</script>

<style lang="css" scoped>
</style>