<template>
  <div>
    <div class="container-fluid pt-5">

        <div class="row">
          <div class="col col-md-6  col-lg-4">
            <h2>Bedrijfsgegevens</h2>
            
            <inputField @input="checkKVK" label="KvK nummer" placeholder="13245678915" v-model="payload.segments.company.kvk" type="text"/>
            
            <inputField placeholder="Bedrijfsnaam Holding B.V." v-model="payload.segments.company.name" type="text" label="Bedrijfsnaam" />
            <inputField placeholder="NL001234567B01" v-model="payload.segments.company.vat_number" type="text" label="BTW Nummer" />

            <fieldset>
              <legend>Factuuradres</legend>

                <div class="row">
                  <div class="col-9">
                    <inputField placeholder="Hoofdstraat" v-model="payload.segments.company.invoice_street" type="text" label="Straatnaam" />
                  </div>
                  <div class="col-3">
                    <inputField  label="Huisnr." placeholder="21 A" v-model="payload.segments.company.invoice_house_number" type="text"/>
                  </div>
                </div>

                <div class="row">
                  <div class="col-4">
                    <inputField  label="Postcode" placeholder="2244 AB" v-model="payload.segments.company.invoice_postal_code" type="text"/>
                  </div>
                  <div class="col-8">
                    <inputField  label="Plaatsnaam" placeholder="Vestigingsplaats" v-model="payload.segments.company.invoice_city" type="text"/>
                  </div>
                </div>
                


              <div class="row">
                <div class="col">
                  <inputField  label="Contactpersoon" ref="contact_name" placeholder="Voornaam" v-model="payload.segments.company.contact_first_name" type="text"/>
                </div>
                
                <div class="col">
                  <inputField  label="&nbsp;" placeholder="Achternaam" v-model="payload.segments.company.contact_last_name" type="text"/>
                </div>
              </div>
              
              <inputField  label="Contactpersoon E-mail" placeholder="Achternaam" v-model="payload.segments.company.contact_email" type="text"/>

            </fieldset>

          </div>

          <div class="col col-md-6  col-lg-4">
            <h2>Gegevens klantenservice</h2>

            <inputField  label="Naam aanbieder" placeholder="Naam van het uitje" v-model="payload.segments.company.merchant_name" type="text"/>

            <fieldset>
              <legend>Bezoekadres</legend>
              <div class="row">
                <div class="col-9">
                  <inputField  label="Straatnaam" placeholder="Hoofdstraat" v-model="payload.segments.company.street" type="text"/>
                </div>
                <div class="col-3">
                  <inputField  label="Huisnr." placeholder="21 A" v-model="payload.segments.company.house_number" type="text"/>
                </div>
              </div>

              <div class="row">
                <div class="col-4">
                  <inputField  label="Postcode" placeholder="2244 AB" v-model="payload.segments.company.postal_code" type="text"/>
                </div>

                <div class="col-8">
                  <div class="form-group">
                    <label for="comapny_city2">Plaatsnaam</label>
                    <input type="email" class="form-control" id="comapny_city" placeholder="Vestigingsplaats" v-model="payload.segments.company.city">
                  </div>                
                </div>
              </div>
            </fieldset>


            <div class="form-group">
              <label for="email">E-mailadres</label>
              <input type="text" class="form-control" id="email" placeholder="klantenservice@example.org" v-model="payload.segments.company.email">
            </div>            
            <div class="form-group">
              <label for="phone">Telefoonnummer</label>
              <input type="text" class="form-control" id="phone" placeholder="099 1234567" v-model="payload.segments.company.phone">
            </div>            
            <div class="form-group">
              <label for="website">Website</label>
              <input type="text" class="form-control" id="website" placeholder="www.mijnwebsite.nl" v-model="payload.segments.company.url">
            </div>            
          </div>

          <div class="col col-md-6 col-lg-4">
          <h2>Uitbetaling</h2>
          <fieldset class="mb-3">
            <!-- <legend>Bankgegevens</legend> -->
            <inputField  label="IBAN nummer" placeholder="NL05BANK00055567890" v-model="payload.segments.company.iban_number" type="text"/>
            <inputField  label="Tennaamstelling" placeholder="Naam rekeninghouder" v-model="payload.segments.company.iban_name" type="text"/>
          </fieldset>       

          <infoblock icon="fas fa-info">
            <p>DagjeWeg.NL Tickets verstuurt iedere maand rapportage over de voorgaande maand. De ticketgelden worden dan binnen enkele dagen overgemaakt.</p>
          </infoblock>

          <infoblock icon="fas fa-question">
            <p>Vragen bij het invullen?</p>
            <p><i class="fas fa-question"></i> 0318 640 289</p>
            <p><i class="fas fa-at"></i> service@dagjewegtickets.nl</p>
          </infoblock>
        </div>

        </div>

    </div>
  </div>
</template>

<script>

import axios from 'axios'
import inputField from '../shared/inputField.vue'
import infoBlock from '../shared/infoBlock.vue'
import { mapFields } from 'vuex-map-fields'

export default {
  components: {inputField, infoblock: infoBlock},
  data () {
    return {
      kvkResponse: []
    }
  },
  computed: {
    ...mapFields([
      // 'payload.segments.company.kvk'
      // ...
    ]),
    payload(){
      return this.$store.getters.payload
    },
    buttons(){
      return this.$store.getters.buttonStates
    }
  },
  methods: {
    handleInput(e){
      // console.log('ok')
      // console.log(e)
      return
    },
    checkKVK(){
      var self = this
      var payload = {...this.payload}
      // var payload = this.payload
      if (this.payload.segments.company.kvk.length == 8){
        this.$refs.contact_name.focus()
        axios.get(`/api/kvk/${this.payload.segments.company.kvk}`)
          .then(function (response) {
            self.kvkResponse = response.data
            payload.segments.company.name = response.data.handelsnaam
            // payload.segments.company.city = response.data.plaats
            // payload.segments.company.street = response.data.straat
            // payload.segments.company.house_number = response.data.huisnummer
            // payload.segments.company.postal_code = response.data.postcode
            payload.segments.company.invoice_city = response.data.plaats
            payload.segments.company.invoice_street = response.data.straat
            payload.segments.company.invoice_house_number = response.data.huisnummer
            payload.segments.company.invoice_postal_code = response.data.postcode
            // payload.segments.company.btw = response.data.postcode    
            payload.has_content = true

            self.$store.dispatch('setInitialPayload',payload)
          })
      }
      
    }
  },
  created (){   
  }
}
</script>

<style lang="css" scoped>

</style>