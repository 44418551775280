<template>
	<div>
		<vue-clip :options="options" :acceptedFiles="acceptedFiles" :on-sending="sending" :on-complete="complete" :on-queue-complete="completedQueue" :on-init="init">
		  <template slot="clip-uploader-action" slot-scope="params">
	      <div v-bind:class="{'is-dragging': params.dragging}" class="upload-action bg-light">
	        <div class="dz-message border border-primary mb-4">
	        	<button class="btn btn-primary"><i class="fas fa-upload mr-3"></i> {{placeholder}}</button>
	        </div>
	      </div>
	     </template>

		    <template slot="clip-uploader-body" slot-scope="props">
		    	<div class="list-group">
					  <div class="list-group-item d-flex justify-content-between px-3" :key="`file-${file.id}`" v-for="file in files">
					  	<span class="small d-flex">
                <img v-bind:src="thumbToShow(file)" v-if="file.thumb && !file.icon" class="img-thumbnail mr-2" style="max-width: 40px; max-height: 40px;" width="40" height="40" />
                <i class="fa-2x mx-2" v-bind:class="file.icon" v-if="!file.thumb"></i>
                <div class="d-inline-block align-self-center">
						  		<a :href="file.url" target="_blank" >{{ file.filename }}</a>
					  			<span v-if="file.permission_string.length"><br>{{file.permission_string}}</span>
					  		</div>
					  	</span>
              <div>
                <span class="text-muted mr-2 small">{{ file.filesize }}</span>
  					  	<button @click="deleteFile(file.id)" class="btn btn-sm align-self-center btn-outline-danger"><i class="fas fa-trash"></i></button>
              </div>
					  </div>
						<div class="list-group-item d-flex justify-content-between px-3" v-for="newfile in props.files" :key="newfile">
		      		<img v-bind:src="newfile.dataUrl" class="img-thumbnail mr-2" style="max-width: 40px; max-height: 40px;" width="40" height="40" />	
		        	<span class="small align-self-center">{{ newfile.name }}</span>
		        	<i class="fa fa-spinner fa-spin align-self-center"></i>
			      </div>
					</div>
		    	
		    </template>
	  </vue-clip>

	</div>
</template>

<script>

import axios from 'axios'

export default {


	props: ["uploadMultiple", "placeholder", "acceptedFiles", "handle", "required"],
  data () {
    return {
    	uploader: null,
      ready: false,
    }
  },
	
  computed: {
  	entryKey() {
			console.log("entrykey")
  		return this.$store.getters.key
  	},
  	allFiles(){
			console.log("allfiles")
			return this.$store.getters.files
  	},
  	files(){
			console.log("files")
  		return this.$store.getters.files[this.handle]
  	},
	  options() {
			console.log("options")
	  	// acceptedFiles
	  	// ['image/*', 'application/pdf']
	  	return {
				acceptedFiles: this.acceptedFiles,
	  		uploadMultiple: true,
        url: `/entries/${this.entryKey}/uploads.json?handle=${this.handle}`,
        paramName: 'upload[files]'
	  	}
    },

  },
	methods: {
		thumbToShow(file) {
			console.log("creating thumb or at least in thumbtoshow")
      if (file.icon) {
				return ''
      } else if (file.thumb == undefined) {
				return ''
      } else if (file.thumb.includes('png') || file.thumb.includes('PNG') || file.thumb.includes('gif') || file.thumb.includes('JPG') || file.thumb.includes('jpg')) {
				return file.thumb
      } else {
				return ''
      }
    },
		deleteFile(id){
			axios.get(`/entries/${this.entryKey}/delete-file/${id}.json`)
			.then(response => {
				// console.log(response)
        this.$store.dispatch('setFiles',response.data.files)
        this.$store.dispatch('setPhotosWithoutPermission', response.data.photos_without_permission)
      })
		},
  	init (uploader) {
			console.log("init")
   		this.uploader = uploader
    },
		sending (file, xhr, formData) {
			console.log("sending")
      this.ready = false
      formData.append('authenticity_token', document.querySelector('meta[name="csrf-token"]').getAttribute('content'))
    },
    completedQueue(){
			console.log("completedqueue")
			this.ready = true
    },
    complete (file, status, xhr) {
			console.log("completedqueue")
      
      // Adding server id to be used for deleting
      // the file.
      // file.addAttribute('id', xhr.response.id)
      setTimeout(function () {
        if (this.ready){
          
          this.uploader.files = []
          this.$store.dispatch('setFiles', JSON.parse(xhr.response).files)  
          this.$store.dispatch('setPhotosWithoutPermission', JSON.parse(xhr.response).photos_without_permission)
        }
      }.bind(this), 500)
      
      
    }
  },
}
</script>

<style lang="css" scoped>
	.is-dragging {
		background: #888 !important;
	}
	table td{
		vertical-align: middle !important;
	}

	.dz-message{
		text-align: center;
		padding: 20px;
		border-style: dashed !important;
		border-radius: 4pt;
		cursor: pointer;
	}
</style>