// seed data
import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex'
import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)

export default new Vuex.Store({

  state: { // data
    key: '',
    buttonStates: {},
    files: [],
    selectedPage: '',
    savedPayload: {},
    photoSettings: {remember: true},
    photosWithoutPermissions: [],
    payload: {
      segments: {company: {}},
      has_content: false
    }
  },

  getters: { // computed properties
    getField,
    key (state) {
      return state.key
    },
    photosWithoutPermissions (state){
      return state.photosWithoutPermissions
    },
    payload (state){
      return state.payload
    },
    savedPayload (state){
      return state.savedPayload
    },
    files (state){
      return state.files
    },
    buttonStates (state){
      return state.buttonStates
    },
    selectedPage (state){
      return state.selectedPage
    },
    photoSettings (state){
      return state.photoSettings
    }
  },

  actions: {
    setPhotoSettings(context, settings){
      context.commit("setPhotoSettings", settigns)
    },
    updateList(context, key) {
      context.commit("updateList", key)
    },
    setKey(context, key){
      context.commit("setKey", key)
    },
    setPhotosWithoutPermission(context, photos){
      context.commit("setPhotosWithoutPermission", photos)
    },
    setFiles(context, files){
      context.commit("setFiles", files)
    },
    setSelectedPage(context, page){
      context.commit("setSelectedPage", page)
    },
    setButtonState(context, options){
      // {'button': 'footerSave', 'disabled': false, 'class': 'btn-success', 'message': 'Opgeslagen!'}

      // save the state of the button and remove it in xx miliseconds
      context.commit("setButtonState", options)
    },
    setButtonStateReset(context, options){
      // {'button': 'footerSave', 'disabled': false, 'class': 'btn-success', 'message': 'Opgeslagen!'}

      // save the state of the button and remove it in xx miliseconds
      context.commit("setButtonState", options)
      setTimeout(function() {
        context.commit("clearButtonState", options.button)
      }, 1500) 
    },
    setInitialPayload(context, payload){
      context.commit("setPayload", payload)
    },
    setSavedPayload(context, payload){
      context.commit('setSavedPayload', payload)
    },
    setInitialPayloadSegement(context, segment){
      
      if (this.getters.payload.has_content == false) {
        // console.log("adding segment " + segment)
        context.commit("addSegementToPayload", segment)  
      }
      
    }

  },

  mutations: {
    updateField,
    setSelectedPage (state, page){
      state.selectedPage = page
    },
    setPhotoSettings(state, settigns){
      state.photoSettings = settings
    },
    setKey(state, key){
      state.key = key
    },
    updateList(state, key) {
      state.payload.segments.conditions = key
    },
    setFiles(state, files){
      // console.log("setting files")
      // console.log(files)
      state.files = files
    },
    setPhotosWithoutPermission(state, photos){
      state.photosWithoutPermissions = photos
    },
    setSavedPayload(state, payload){
      var deep = _.cloneDeep(payload);
      state.savedPayload = deep
    },
    setPayload( state, payload){
      // console.log("Payload:")
      // console.log(payload)
      if (payload.segments != undefined){
        // console.log("setting initial payload")
        // console.log(payload.segments.company)
        state.payload = payload
      }
      // state.payload.segments  = {company: {kvk: 'nieuw'}}
    },
    addSegementToPayload( state, segment){
      for (var key in segment) {
        state.payload.segments[key] = segment[key]
      }      
    },
    clearButtonState(state, button){
      var states = {...state.buttonStates}
      // states[options.button] = options
      delete states[button]
      state.buttonStates = states
    },
    setButtonState( state, options ){
      var states = {...state.buttonStates}
      states[options.button] = options
      state.buttonStates = states
    }

  }

})
