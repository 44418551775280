<template>

  <div class="bg-primary rounded text-white p-3 pt-4 mb-5" style="position: relative; font-size: 1.2rem;">
    <div class="bg-primary rounded-circle circle"><i :class="icon"></i></div>
    <slot></slot>
  </div>
</template>

<script>
export default {

  props: ["icon"],
  data () {
    return {

    }
  }
}
</script>

<style lang="css" scoped>
p{
  font-size: 0.8em;
}
.circle{
  position: absolute;
  top: -15px;
  left: calc(50% - 15px);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  padding-top: 3px;
}
</style>