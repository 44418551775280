<template>
  <div>
    <div class="container pt-5">
      <div class="row">
        <div class="col">
          <h2>Aanmelding voltooien</h2>
          <p class="lead">Helemaal klaar met invullen? Klik op de knop hieronder om de aanmelding te voltooien. DagjeWeg.NL Tickets ontvangt dan bericht en zal de verkooppagina verder klaarzetten.</p>
          <b-form-checkbox
            id="checkbox-1"
            v-model="email"
            name="checkbox-1"
            value="accepted"
            unchecked-value="not_accepted"
          >Ja, ik wil de DagjeWeg.NL Tickets nieuwsbrief ontvangen op</b-form-checkbox>
          <inputField placeholder="@ E-mailadres" v-model="payload.segments.company.newsletter_email" type="text" label=""/>
          <formButton @click="$emit('completeForm')"  class="btn btn-primary" name="completeForm" title="Formulier verzenden"></formButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import inputField from '../shared/inputField.vue'
import { mapFields } from 'vuex-map-fields'


export default {
  components: {inputField},
  data () {
    return {
      email: "accepted",
    }
  },
  computed: {
    payload(){
      return this.$store.getters.payload
    },
    ...mapFields([
      // 'payload.segments.company.kvk'
      // ...
    ]),
  }
}
</script>

<style lang="css" scoped>
</style>