<template>
  <div>
    <input class="form-control" type="text" v-model="condition.content">
  </div>
</template>

<script>
export default {

  props: ["condition"],
  data () {
    return {

    }
  }
}
</script>

<style lang="css" scoped>
</style>