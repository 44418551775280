<template>
  <div>
    <div class="container-fluid py-4 bg-light">
      <div class="row">
        <div class="col col-md-6 mx-auto text-center bg-white rounded shadow-sm py-2"  v-if="!completed">
          <h1>Afbeeldingen voor DagjeWeg.Tickets</h1>
          <p class="lead">In onderstaande formulier voeg je een aantal afbeeldingen toe waarvan je wilt dat wij er gebruik van maken voor je DagjeWeg.Tickets pagina. Door hier afbeeldingen te uploaden geef je ons het recht om de afbeeldingen te gebruiken.</p> 
        </div>
      </div>
    </div>
    <div class="container pt-1" v-if="completed">
      <div class="row">
        <div class="col text-center">
          <p class="lead">Bedankt voor het invullen!<br><br>Je afbeeldingen zijn opgeslagen. Je wordt binnen enkele seconden terug gestuurd naar de DagjeWeg.Tickets backoffice.</p>
        </div>
      </div>
    </div>

    <div class="container pt-1" v-if="!completed">
      <div class="row">
        <div class="col">
          <ImageUploader class="" handle="images" :uploadMultiple="true" :optional_logo="optional_logo" :acceptedFiles="'image/*,application/pdf,application/*'" placeholder="Upload afbeeldingen"></ImageUploader>
        </div>
      </div>
      <b-modal size="xl" no-close-on-esc no-close-on-backdrop hide-footer hide-header centered v-model="openPermissionModal">
        <photoPermission @deleteFile="deleteFile" @savePhotoPermission="savePhotoPermission" :entryKey="entrykey" :file="photosWithoutPermissions[0]"></photoPermission>
      </b-modal>
    </div>
    <div v-farewell="onLeave"></div>
    <b-modal hide-footer centered hide-header v-model="showLeaveModal"><b>Let op:</b> je hebt nog niet-opgeslagen informatie op deze pagina. Wil je niet eerst je informatie opslaan?</b-modal>
    <div class="container-fluid bg-light mt-4 py-4" v-if="!completed">
      <div class="row">
        <div class="col-6 mx-auto">
          <formButton @click="completeForm" class="btn btn-success btn-lg btn-block" name="footerSave" :disabled="btnDisabled" title="Klaar? Formulier verzenden!"></formButton>
          <p class="text-muted text-center"><b>Let op:</b> na het versturen kun je je gegevens niet meer aanpassen.</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ImageUploader from '../shared/imageUploader.vue'
import photoPermission from '../shared/photoPermission.vue'
import axios from 'axios'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

  export default {

    props: ["entrykey", "files", "photos_without_permission", "payload", "optional_logo"],

    components: {
      ImageUploader, photoPermission
    },

    data() {
      return {
        showLeaveModal: false,
        completed: false,
      }
    },

    computed: {
      btnDisabled(){
        return (this.photosWithoutPermissions.length < 0, !this.$store.getters.photosWithoutPermissions, this.$store.getters.files.images ? this.$store.getters.files.images.length < 1 : '')
      },
      openPermissionModal() {
        return this.photosWithoutPermissions.length > 0
      },
      photosWithoutPermissions(){
        return this.$store.getters.photosWithoutPermissions
      },
    },

    methods: {
      deleteFile(file){
        axios.get(`/entries/${this.entrykey}/delete-file/${file.id}.json`)
        .then(response => {
          this.$store.dispatch('setFiles',response.data.files)
          this.$store.dispatch('setPhotosWithoutPermission', response.data.photos_without_permission)
        })
      },
      savePhotoPermission(file){
        return axios.put(`/entries/${this.entrykey}/update-file/${file.id}`, {file: file})
        .then(response => {
          this.$store.dispatch('setFiles', response.data.files)
          this.$store.dispatch('setPhotosWithoutPermission', response.data.photos_without_permission)
          console.log("DONE")
        })
      },
      completeForm(){
        return axios.put(`/entries/${this.entrykey}?complete=true`, {payload: this.currentPayload})
          .then(response => {
            this.completed = true
            this.$store.dispatch('setButtonStateReset', {'button': 'completeForm', 'disabled': false, 'class': 'btn-success', 'message': 'Bedankt! De ingevoerde informatie is vezonden.'})
            // window.location =  `https://backoffice.dagjewegtickets.nl/via/image_imports/${this.entrykey}`
            window.location =  `http://backoffice.lvh.me:8080/via/image_imports/${this.entrykey}`

          // return response.data
        })
      },
    },

    created() {
      this.$store.dispatch('setKey', this.entrykey)
      this.$store.dispatch('setFiles', this.files)
      this.$store.dispatch('setPhotosWithoutPermission', this.photos_without_permission)
    },
    
  }
</script>