<template>
	<button @click="$emit('click')" :class="className" v-bind:disabled="disabled">
		{{ showTitle }}
	</button>
</template>

<script>
export default {
	props: ["title", "name", "disabled"],
  data () {
    return {

    }
  },
  computed: {
  	currentState(){
  		if (this.$store.getters.buttonStates[this.name]){
				return this.$store.getters.buttonStates[this.name].message	
  		} else {
  			return false
  		}
  		
  	},
  	showTitle(){
  		if (this.currentState){
			 return this.currentState
  		} else {
  			return this.title
  		}
  	},
  	className(){
  		if (this.currentState){
  			return this.$store.getters.buttonStates[this.name].class	 
  		} else {
  			return ''
  		}
  	}
  }
}
</script>

<style lang="css" scoped>
</style>