<template>
  <div>
    <div class="container-fluid py-5 bg-light">
      <div class="row">
        <div class="col col-md-6 mx-auto text-center bg-white rounded shadow-sm py-2">
          <h1>Werken bij Eskes Media B.V.</h1>
          <p class="lead">Sla in onderstaande formulier jouw persoonlijke gegevens op. Alleen jij en de beheerders vanuit Eskes Media B.V. kunnen deze pagina zien.</p> 
          <p class="small text-muted">In het kader van de AVG heb je het recht om vergeten te worden, zodra we je gegevens niet meer nodig hebben. Stuur een e-mail naar administratie@eskesmedia.nl als je daarvan gebruik wilt maken. We vragen je alleen het minimale dat we nodig hebben.</p>
        </div>
      </div>
    </div>

    <div class="container pt-5" v-if="completed">
      <div class="row">
        <div class="col text-center">
          <p class="lead">Bedankt voor het invullen {{currentPayload.first_name}}!<br><br>Je gegevens zijn verstuurd naar Eskes Media. Je kunt deze pagina nu sluiten.</p>
        </div>
      </div>
    </div>

    <div class="container pt-5" v-if="!completed">
      <div class="row">
        <div class="col">
          <fieldset>
            <legend>Persoonlijke gegevens</legend>

              <div class="row">
                <div class="col-6">
                  <inputField placeholder="Je voornaam" v-model="currentPayload.first_name" type="text" label="Voornaam" />
                </div>
                <div class="col-6">
                  <inputField placeholder="Je achternaam" v-model="currentPayload.last_name" type="text" label="Achternaam" />
                </div>
              </div>
              <inputField placeholder="E-mailadres" v-model="currentPayload.email" type="text" label="E-mail" />
              <inputField placeholder="06 12345678" v-model="currentPayload.phone" type="phone" label="Telefoonnummer" />
              <inputField placeholder="25-12-1995" v-model="currentPayload.birthday" label="Geboortedatum" />

              <inputField placeholder="999999990" mask="111111111" v-model="currentPayload.personal_number" label="BSN Nummer" />
              <uploader class="" handle="passport" :uploadMultiple="false" :acceptedFiles="'image/*,application/pdf,application/*'" placeholder="Upload een kopie van je paspoort of geldig ID bewijs"></uploader>
          </fieldset>
        </div>


        <div class="col">
          <fieldset>
            <legend>Adresgegevens</legend>
            <inputField placeholder="Straatnaam 123" v-model="currentPayload.address" type="text" label="Straatnaam en huisnummer" />
            <div class="row">
              <div class="col-6">
                <inputField placeholder="2244 AB" mask="1111 AA" v-model="currentPayload.postal_code" type="text" label="Postcode" />
              </div>
              <div class="col-6">
                <inputField placeholder="Plaatsnaam" v-model="currentPayload.city" type="text" label="Plaatsnaam" />
              </div>
            </div>

          </fieldset>
          <fieldset>
            <legend>Uitbetaling</legend>
            <inputField placeholder="NL03INGB0001114466" v-model="currentPayload.bank_account" label="Rekeningnummer" />
          </fieldset>

        </div>
      </div>
    </div>
    <div class="container-fluid bg-light mt-4 py-4" v-if="!completed">
      <div class="row">
        <div class="col-6 mx-auto">
          <formButton @click="completeForm" class="btn btn-success btn-lg btn-block" name="footerSave" :disabled="btnDisabled" title="Klaar? Formulier verzenden!"></formButton>
          <p class="text-muted text-center"><b>Let op:</b> na het versturen kun je je gegevens niet meer aanpassen.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import inputField from '../shared/inputField.vue'
import { mapFields } from 'vuex-map-fields'
import uploader from '../shared/uploader.vue'
import axios from 'axios'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


export default {
  components: {
    inputField, uploader
  },
  props: ["payload", "entrykey", "files", "page"],
  data () {
    return {
      showLeaveModal: false,
      completed: false
      // currentPage: 'company'
    }
  },
  computed: {
    ...mapFields([
      // 'payload.first_name'
      // ...
    ]),
    btnDisabled(){
      return  !(this.currentPayload.first_name && this.currentPayload.last_name && this.currentPayload.postal_code && this.currentPayload.bank_account && this.currentPayload.city && this.currentPayload.email)
    },
    currentPayload(){
      return this.$store.getters.payload
    },
    buttons(){
      return this.$store.getters.buttonStates
    }
  },
  methods: {
    deleteFile(file){
      axios.get(`/entries/${this.entrykey}/delete-file/${file.id}.json`)
      .then(response => {
        // console.log(response)
        this.$store.dispatch('setFiles',response.data.files)
        this.$store.dispatch('setPhotosWithoutPermission', response.data.photos_without_permission)
      })
    },
    completeForm(){
      return axios.put(`/entries/${this.entrykey}?complete=true`, {payload: this.currentPayload})
        .then(response => {
          this.completed = true
          this.$store.dispatch('setButtonStateReset', {'button': 'completeForm', 'disabled': false, 'class': 'btn-success', 'message': 'Bedankt! De ingevoerde informatie is vezonden.'})
   
        // return response.data
      })
    },
    submitForm(){
      return axios.put(`/entries/${this.entrykey}`, {payload: this.currentPayload})
      .then(response => {
        // this.completed = true
        this.$store.dispatch('setButtonStateReset', {'button': 'footerSave', 'disabled': false, 'class': 'btn-success', 'message': 'Opgeslagen!'})
        this.$store.dispatch('setSavedPayload', this.currentPayload)
        // return response.data
      })
    },
    // setInitialSegments(){
    //   var segments = [{personal: {}}]
    //   for (var segment in segments){
    //     this.$store.dispatch('setInitialPayloadSegement', segments[segment])
    //   }
    // }
  },
  created(){
    this.$store.dispatch('setInitialPayload', this.payload)
    this.$store.dispatch('setKey', this.entrykey)
    this.$store.dispatch('setFiles', this.files)
    // this.setInitialSegments()
  }
}
</script>

<style lang="css" scoped>
/*@import 'bootstrap/dist/css/bootstrap.css'
@import 'bootstrap-vue/dist/bootstrap-vue.css'*/



</style>