<template>
	<div v-if="file">
		<div class="row mb-1">
			<div class="col">
				<strong>{{ file.filename }}</strong><br>
				<small class="text-muted">
					<b>Bestandstype:</b> {{ file.filetype }} &middot; <b>Bestandsgrootte</b>: {{ file.filesize }}
				</small>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<img :src="file.thumb" class="rounded shadow-sm mb-3 w-100" />				
			</div>

			<div class="col extra-margin text-left">
				<b-form-group label="Auteursrechten *">
		      <b-form-radio-group
		        v-model="file.permission_type"
		        :options="options"
		        name="radios-stacked"
            buttons
		        button-variant="outline-primary text-left"
		        stacked
		      ></b-form-radio-group>
		    </b-form-group>
			</div>

		</div>
		<div class="row">
			<div class="col-6">
				<div class="form-group">
				  <label>Omschijving *</label>
				  <input maxlength="60" class="form-control" v-model="file.alt_text" placeholder="Beschijf de foto" type="text"/>
					<small>Deze tekst wordt als alt tekst gebruikt van de foto. Zo kunnen mensen met een visuele beperking ook de foto begrijpen.</small>
			  </div> 
			</div>
		</div>
		<div class="row">
			<div class="col">
				<div class="form-group">
				  <label>Fotograaf</label>
				  <input class="form-control" v-model="file.maker_name" placeholder="Naam fotograaf" type="text"/>
			  </div>            
			</div>
			<div class="col">
				<div class="form-group">
				  <label>E-mailadres fotograaf</label>
				  <input class="form-control" v-model="file.maker_email" placeholder="E-mailadres fotograaf" type="text"/>
			  </div>            
			</div>
			<div class="col">
				<div class="form-group">
					<label>&nbsp;</label>
		      <b-form-checkbox
			      id="checkbox-1"
			      v-model="remember"
			      name="checkbox-1"
			      value="accepted"
			      unchecked-value="not_accepted"
			    >
			      Onthoud mijn instellingen voor de volgende foto
			    </b-form-checkbox>
			  </div>            
			</div>
			<div class="col">
				<div class="form-group text-center">
					<label>&nbsp;</label>
				  <button @click="$emit('savePhotoPermission', file)" v-bind:disabled="btnIsDisabled" class="btn btn-block btn-primary">Opslaan</button>   
				  <button @click="$emit('deleteFile', file)" class="mt-2  mb-0 btn-text btn text-danger">verwijder foto</button>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col text-muted small text-center">
				<hr>
				Let op: Op een foto zit altijd auteursrecht. Verzeker je ervan dat je over de juiste rechten beschikt. Meer informatie over auteursrecht: <a href="https://www.dagjeweg.nl/contact#:~:text=Alle%20auteursrechten,%20databankrechten%20en%20andere" target="_blank">dagjeweg.nl/contact</a>. Wij verhalen auteursrechtenclaims altijd op het bedrijf dat de afbeeldingen aangeleverd heeft. Ter controle sturen wij de fotograaf een e-mail als zijn/haar foto geplaatst is.
			</div>
		</div>
	</div>
</template>

<script>
export default {

	props: ["file"],
  data () {
    return {
    	remember: true,
    	// file: null,
    	options: [
    		{text: 'Deze foto is <b>in opdracht van ons</b> gemaakt. Wij hebben alle rechten vastgelegd en de naam van de fotograaf hoeft niet vermeld te worden.', value: 'own_photo_without_credits'},
    		{text: 'Deze foto is in opdracht van ons gemaakt. Wij hebben alle rechten vastgelegd en de naam van de fotograaf moet wel vermeld worden.', value: 'own_photo_with_credits'},
    		{text: 'Deze foto hebben wij gekocht. Wij hebben alle rechten vastgelegd en de naam van de fotograaf hoeft niet vermeld te worden.', value: 'bought_without_credits'},
    		{text: 'Deze foto hebben wij gekocht. Wij hebben alle rechten vastgelegd en de naam van de fotograaf moet wel vermeld worden.', value: 'bought_with_credits'},
    		{text: 'Deze foto hebben wij zelf gemaakt. Er hoeft geen fotograaf vermeld te worden.', value: 'own'}
    	]
    }
  },
  computed: {
  	btnIsDisabled(){
  		if (this.file.permission_type == null){
  			return true
			} else if(this.file.alt_text == null) {
				return true
			} else if (this.file.permission_type.includes('with_credits')){
  			return (this.file.maker_name == null || !this.file.maker_name.length) || (this.file.maker_email == null || !this.file.maker_email.length)
  		} else {
  			return false
  		}
  	}
  },
  methods: {
  },
  mounted(){
  	// this.file = this.setFile()
  }
}
</script>

<style lang="css">
.extra-margin .form-group .custom-radio{
	text-align: left;
}
</style>