<template>
  <div>
    <div class="container pt-3">
      <div class="row">
        <div class="col">
          <h1>Afbeeldingen uploaden</h1>
          <p class="lead">
            Sprekende foto's om een goed beeld van het uitje te geven. Let op auteursrecht!
          </p>
        </div>
      </div>
      
	    <div class="row d-flex justify-content-between" v-if="!completed">
	      <div class="col col-md-6 col-lg-6">
          <h5>Logo bedrijf <span v-if="optional_logo"> (optioneel) </span></h5>
          <p>Toegestane bestandsformaten: .png, .jpg</p>
	      	<uploader class="" handle="logo" :uploadMultiple="false" :acceptedFiles="'image/png, image/jpeg'" placeholder="Upload bedrijfslogo"></uploader>

          <h5 class="mt-5">Afbeeldingen</h5>
          <p class="mb-0">Afbeeldingen zijn erg belangrijk voor je verkoop op DagjeWeg.Tickets. Daarom is het essentieel om goede foto's te uploaden van jouw uitje. Mocht je hulp nodig hebben of advies willen hebben? Neem dan contact op met onze redactie: <a href="tel:+31318300010">0318-300010</a></p>
          <p class="mt-1">Toegestane bestandsformaten: .png, .jpg</p>
          <uploader class="" handle="images" :uploadMultiple="true" :acceptedFiles="'image/png, image/jpeg'" placeholder="Upload afbeeldingen"></uploader>
	     	</div>

        <div class="col col-md-6 col-lg-4">
          <infoblock icon="fas fa-info">
            <p>Deze afbeeldingen worden gebruikt op de verkooppagina, op de overzichtpagina's, in artikelen, om reclame te maken voor de ticketverkoop en om DagjeWeg.NL en DagjeWeg.NL Tickets te promoten.</p>
            <p><b>Let op: Op een foto zit altijd auteursrecht.</b> Verzeker jezelf ervan dat je over de juiste rechten beschikt.</p>
            <p>Meer informatie over auteursrecht: <a class="text-white" href="https://www.dagjeweg.nl/contact#:~:text=Alle%20auteursrechten,%20databankrechten%20en%20andere" target="_blank">lees dit artikel.</a></p>
            <p>Wij verhalen auteursrechtenclaims altijd op het bedrijf dat de afbeeldingen aangeleverd heeft.</p>
            <p>Is het auteursrecht van foto's veranderd? Laat het ons weten, dan passen we de afbeeldingen aan.</p>
          </infoblock>

          <infoblock icon="fas fa-question">
            <p>Vragen bij het invullen?</p>
            <p><i class="fas fa-question"></i> 0318 640 289</p>
            <p><i class="fas fa-at"></i> service@dagjewegtickets.nl</p>
          </infoblock>
        </div>
	    </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'
import inputField from './inputField.vue'
import infoBlock from './infoBlock.vue'
import uploader from './uploader.vue'
import { mapFields } from 'vuex-map-fields'

export default {
  props: ['optional_logo'],
  components: {inputField, uploader, infoblock: infoBlock},
  data () {
    return {
      kvkResponse: [],
    }
  },
  computed: {
    ...mapFields([
      // 'payload.segments.company.kvk'
      // ...
    ]),
    payload(){
      return this.$store.getters.payload
    },
    // logo(){
    // 	return this.payload.segments.company.logo
    // },
    buttons(){
      return this.$store.getters.buttonStates
    }
  },
  created (){   
  }
}
</script>

<style lang="css">

</style>