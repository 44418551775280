
<template>
  <b-container fluid>
    <!-- User Interface controls -->
    <b-row>
      <b-col md="6" class="my-1">
        <b-form-group label-cols-sm="3" label="Zoeken" class="mb-0">
          <b-input-group>
            <b-form-input v-model="filter" placeholder="Type om te zoeken"></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Wis</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col md="6" class="my-1">
        <b-form-group label-cols-sm="3" label="Sorteren" class="mb-0">
          <b-input-group>
            <b-form-select v-model="sortBy" :options="sortOptions">
              <option slot="first" :value="null">-- geen --</option>
            </b-form-select>
            <b-form-select v-model="sortDesc" :disabled="!sortBy" slot="append">
              <option :value="false">Opl.</option> <option :value="true">Afl.</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>
      
    </b-row>

    <!-- Main table element -->
    <b-table
      show-empty
      stacked="md"
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
    >
      
      <template slot="isActive" slot-scope="row">
        {{ row.value ? 'Yes :)' : 'No :(' }}
      </template>

      <template slot="key" slot-scope="row">
        <div class="d-flex justify-content-between">
          <div class="btn-group">
            <a :href="`/entries/${row.item.key}`" data-method="delete" class="btn btn-sm btn-outline-danger" data-confirm="Weet je het zeker?">Verwijderen</a>
            <a :href="`/entries/${row.item.key}`" target="_blank" class="btn btn-sm btn-outline-primary">Bekijk</a>
            <a v-if="row.item.locked" :href="`/entries/${row.item.key}/unlock`" class="btn btn-sm btn-outline-primary">Ontgrendel</a>
            <a v-if="!row.item.locked" :href="`/entries/${row.item.key}/lock`" class="btn btn-sm btn-outline-primary">Vergrendel</a>
            <a v-for="button in extrabuttons" :key="button" :href='`${button.link}?key=${row.item.key}`' target="_blank" :class='`btn btn-sm btn-outline-${button.color}`'>{{button.label}}</a>
            <a v-if="!row.item.completed && project != 3" :href="`/entries/${row.item.key}/toggle_complete`" class="btn btn-sm btn-outline-success">Markeer als actief</a>
          </div>
          <div v-if="row.item.completed_at" class="text-success">
            <i class="fas fa-check-double"></i>
          </div>
        </div>
      </template>

      <template slot="row-details" slot-scope="row">
        <b-card>
          <ul>
            <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
          </ul>
        </b-card>
      </template>
    </b-table>

    <b-row>
      <b-col md="6" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>

    <!-- Info modal -->
    <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">
      <pre>{{ infoModal.content }}</pre>
    </b-modal>
  </b-container>
</template>

<script>

  export default {
    props: ["items", "fields", "extrabuttons", "project"],
    data() {
      return {
        totalRows: 1,
        currentPage: 1,
        perPage: 25,
        pageOptions: [5, 10, 15],
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        infoModal: {
          id: 'info-modal',
          title: '',
          content: ''
        }
      }
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      }
    },
    mounted() {
      // Set the initial number of items
      this.totalRows = this.items.length
    },
    methods: {
      info(item, index, button) {
        this.infoModal.title = `Row index: ${index}`
        this.infoModal.content = JSON.stringify(item, null, 2)
        this.$root.$emit('bv::show::modal', this.infoModal.id, button)
      },
      resetInfoModal() {
        this.infoModal.title = ''
        this.infoModal.content = ''
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      }
    }
  }
</script>